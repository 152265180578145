(function (angular, _) {
    const  MyHippoFormlyDirectives = angular.module('MyHippoFormly.Directives');
    MyHippoFormlyDirectives.directive('mhProducerDetailSubOrg', mhProducerDetailSubOrg);

    function mhProducerDetailSubOrg (toaster) {
        return {
            restrict: 'E',
            templateUrl: 'common/formly-directives/producer-sub-org/template.html',
            wrapper: [],
            overwriteOk: true,
            controller: function ($scope, APIService, $mdDialog) {
                const getFirstSubOrgName = () => {
                    const firstSubOrgId = _.get($scope.selectedOrgs, '0.org_id', '');
                    if (firstSubOrgId) {
                        const firstSelectedSubOrg = _.find($scope.allSubOrgs, o => o.id === firstSubOrgId);
                        const firstSubOrgName = _.get(firstSelectedSubOrg, 'name', '');
                        if (firstSubOrgName.length > 20) {
                            // Truncate if more than 18 characters
                            return firstSubOrgName.substr(0, 18) + '...';
                        }
                        return firstSubOrgName;
                    }
                };

                $scope.$watch('selectedOrgs', function () {
                    $scope.firstSubOrgName = getFirstSubOrgName();
                }, true);

                APIService.findOrganizations().then(({ organizations }) => {
                    $scope.allSubOrgs = organizations.filter(o => o.parent_id !== null);
                    $scope.firstSubOrgName = getFirstSubOrgName();
                    $scope.openSubOrgsList = (selectedOrgs) => $mdDialog.show({
                        templateUrl: 'views/templates/listSubOrgModal.html',
                        parent: angular.element(document.body),
                        controller: function ($scope) {
                            $scope.close = () => $mdDialog.hide();
                            $scope.selectedOrgs = selectedOrgs.map(o => {
                                const matchingOrg = _.find(organizations, org => org.id === o.org_id);
                                return Object.assign({}, o, { name: matchingOrg.name });
                            });
                        },
                    });
                });
                $scope.producerId = _.get($scope.model, 'user_id');
                $scope.selectedOrgs = _.get($scope.model, 'data.sub_organizations', []);
            }
        };
    }
})(window.angular, window._);
